#paginationContainer {
    margin-bottom: 1em;
    margin-top: 1em;
    display: flex;
    flex-direction: row;
}

#paginationContainer button {
    margin: 1em;
    width: 7em;
}

@media screen and (max-width: 480px) {
    #paginationContainer button {
        margin: 1em;
        width: 8em;
        height: 3em;
    }
}