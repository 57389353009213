.story {
    margin: 0.25em 0 0.25em 0;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    background-color: #FAFAFA;
    width: 98%;
    min-height: 7em;
    max-height: 7em;
}

.mainContentContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 7em;
    max-height: 7em;
}

.horizontalDivider {
    width:  100%;
}

.storyImage, .noStoryImage {
    height: 70px;
    width: 70px;
}

.noStoryImage {
    border: 1px solid gray;
    text-align: center;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.storyContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 7em;
    max-height: 7em;
}

.storyInformationContainer {
    display: flex;
    flex-direction: row;
    justify-content: left;
    height: 2.5em;
}

.storyInformationContainer p, .storyInformationContainer a {
    font-size: 0.75em;
    color: gray;
}

.storyHeaderContainer {
    display: flex;
    flex-direction: column;
}

.paragraphDivider {
    color: gray;
    opacity: 50%;
}

.points {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.storyHeadline {
    display: flex;
    flex-direction: row;
}

.storyTitle {
    text-align: left;
    font-size: 1.1rem;
}

.storyUrl {
    font-size: 0.6em;
    color: gray;
    width: 10em;
    margin-left: 0.5em;
    margin-right: 0.5em;
}

.storyDetailLink {
    display: flex;
    align-items: center;
    flex-direction: row;
    color: #333333;
    text-decoration: none;
    text-align: left;
    margin-left: 0.5em;
    min-height: 5.5em;
}

.thumbnailContainer {
    min-height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 0.5em;
}

.storyIndex {
    margin-left: 0.1em;
    margin-right: 0.25em;
    margin-top: 0;
    font-size: 0.75em;
    color: gray;
}

.storyTitleContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.storyAuthorContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.author {
    margin-left: 0.5em!important;
    margin-right: 0.5em!important; 
}

.storyAuthorContainer p {
    margin: 0
}

.commentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5em;
}

@media screen and (max-width: 480px) {
    .story {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        background-color: white;
        width: 100%;
    }

    .thumbnailContainer {
        height: 100px;
        width: 100px;
    }

    .storyUrl {
        display: none;
    }

    .storyInformationContainer {
        display: flex;
        justify-content: left;
        flex-direction: column;
        align-items: center;
        margin-top: 0.25em;
    }

    .commentContainer {
        display: none;
    }

    .storyAuthorContainer {
        display: flex;
        align-items: center;
        flex-direction: row;
        width: 100%;
    }

    .storyLinkContainer {
        margin-top: 0.75em;
    }
}