#App {
  width: 100%;
  overflow-x: hidden;
}

#topLevelContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -2.5em;
}

#Stories {
  width: 50%;
}

#noStories {
  margin: 3em;
}

#loadingAnimation {
  margin: 10em;
}

#statement {
  display: flex;
  flex-direction: column;
  width: 75%;
}

#statement sub {
  font-size: 0.75em;
}

#version {
  font-size: 0.5em!important;
}

@media screen and (max-width: 480px) {
  #Stories {
    width: 100%;
  }

  #topLevelContainer {
    margin-top: 0.25em;
  }
}