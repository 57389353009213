#storyDetailContainer {
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -2em;
}

#noCommentsImage {
    width: 25%;
    height: 25%;
}

#noCommentsText {
    color: gray;
}

#subDetailContainer {
    width: 60%;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    display: flex;
    flex-direction: column;
    background-color: #FAFAFA;
    justify-content: center;
    align-items: center;
    margin-bottom: 3em;
}

#textContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

#commentsContainer {
    width: 100%;
    margin-bottom: 2em;
}

#commentsDivider span {
    font-size: 0.75em;
    color: gray;
    font-weight: bold;
    margin-top: 1em;
    margin-bottom: 1em;
}

#commentsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.storyDetailTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin-right: 10%;
}


.storyDetailTitle {
    color: #333333;
    text-decoration: none;
    margin-top: 0.5em;
}

.storyDetailUrl {
    font-size: 0.75em;
    margin-bottom: 1em;
}

#backButtonContainer {
    width: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#backButton {
    margin: 0.5em;
}

.storyDetailImage {
    width: 150px;
    height: 150px;
    margin-bottom: 1em;
}

.noStoryDetailImage {
    border: 1px solid gray;
    text-align: center;
    width: 300px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#storyDetailInformationContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 1em;
}

@media screen and (max-width: 480px) {
    #storyContainer {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }

    #subDetailContainer {
        width: 100%;
    }

    .storyDetailImage {
        width: 300px;
        height: 300px;
    }

    #textContainer {
        display: flex;
        justify-content: center;
        margin-top: 1em;
        min-width: 100%;
    }

    .storyDetailTitle {
        color: #333333;
        text-decoration: none;
    }

    .storyDetailTitleContainer {
        margin-left: 1em;
        margin-right: 1em;
    }

    .storyDetailUrl {
        font-size: 0.75em;
    }
}