header {
    display: flex;
    flex-direction: row;
    top: 0;
    position: sticky;
    margin-left: 2em;
    width: 100%;
}

header p {
    margin: 0 0.25em;
    color: white;
}

#titleContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #333333;
    padding: 1em;
    border-radius: 0 0 0.5em 0.5em;
}

@media screen and (max-width: 480px) {
    header {
        width: 100%;
        height: 2em;
        margin: 0;
    }

    #titleContainer {
        border-radius: 0;
        width: 100%;
        display: flex;
        justify-content: left;
    }
}